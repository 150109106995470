body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #076585;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #fff, #076585);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #fff, #076585); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 

}
@tailwind base;
@tailwind components;
@tailwind utilities;