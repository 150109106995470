


.header-color {
  background: #076585;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #fff, #076585);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #fff, #076585); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}



.landing-grid {
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.avatar-img {
  padding-top: 10em;
  border-radius: 500px ;
  padding: 50px;
  width: 500px;
  height: 500px;
  
 
}

  .banner-text {
  
    color: white;
    padding: 20px;
    text-align: center;
   
    
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: auto;
 
   

  }
  

  



.banner-text h1 {
  font-weight: bold;
  color: white; 
}

.banner-text hr {
  border-top: 5px dotted white;
  
  padding: 8px;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 8px;
  display: center;
  margin: auto;
}

.social-links {
 
}

.Lin:hover {
  transform: scale(1.1);
}


.gt:hover {
  transform: scale(1.1);
}

.Inst:hover {
  transform: scale(1.1);
}

.yt:hover {
  transform: scale(1.1);
}


.social-links i {
  color: white;
  font-size: 2em;
  padding: 10px;
  box-shadow: inset 0px 0px 1px #f8f5f5,
  inset -10px -8px 10px #dddcdc;
  border-radius: 10px;


}


/* Resume Page css */
.resume-left-col {
  background:#076585 ;
  background: linear-gradient(to left, #6696a7, #076585);
  color: white;
  border-radius: 15px;
  padding: 4em;
}
.resume-right-col {
  background: white;
  color: #076585;
  width: '100%';
  margin: '100%';
  border-radius: 15px;
  padding: 4em;;
}

/* contact page css */

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}


/* contact page css */


